import {inject, observable} from 'aurelia-framework';
import {DialogController, DialogService} from 'aurelia-dialog';
import {translations} from "../classes/translations";

const moment = require("moment");
import {LOINC} from "../classes/Codes";
import {UserService} from "../services/UserService";
import {FhirService} from "../services/FhirService";
import {fhirEnums} from "../classes/fhir-enums";
import {RuntimeInfo} from "../classes/RuntimeInfo";
import {CarePlanService} from "../../resources/services/CarePlanService";
import ResourceType = fhirEnums.ResourceType;
import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;
import {NitTools} from "../classes/NursitTools";
import {I18N} from "aurelia-i18n";
import {ConfigService} from "../services/ConfigService";
import ModalPlanningPrescription from "./modal-planning-prescription";

@inject(Element, DialogController, DialogService, UserService, FhirService, CarePlanService, I18N)

export class ModalTodoListProcedureEdit {
    element: HTMLElement;
    controller: DialogController;
    dialogService: DialogService;
    userService: UserService;
    carePlanService: CarePlanService;
    i18n: I18N;

    dpOptions = {
        format: 'DD.MM.YYYY HH:mm',
        locale: translations.language,
        showClear: false,
        showClose: true,
        widgetPositioning: {
            horizontal: 'left',
            vertical: 'auto'
        },
        focusOnShow: false,
        keepInvalid: false,
    };

    patient;
    request;

    procedureIndex = null;
    procedure;
    createMode = false;
    codeSystem = null;

    procedureRequest;

    @observable picker;

    headerTitle: string;

    dateObject: Date;
    dateValue: string;
    shiftReport: boolean;
    duration;
    comment: string;
    notDone: boolean;
    currentStatus: ProcedureStatus;
    statusText: string;
    lastEdit: string;

    reason: string = '';
    otherReason: string = '';

    fhirService: FhirService;

    mappedProcedures = [];

    totalDuration = 0;

    isDiagnostic = false;
    diagnosticPerformer = '';

    carePlan
    conditions
    mappings
    activeMappings = []
    diagnoses = []

    isLoading = false

    isQualificationEnabled = false
    isPrescriptionEnabled = false
    isPrescriptionStrict = false
    isTaskPrescriptionNeeded = false

    prescriptionPresent = 'not-needed'

    qualification = ''

    get isReadOnly() {
        return this.procedureRequest ? moment(this.procedure.performedPeriod.start).isBefore(moment().startOf('day')) && !this.procedureRequest.isActive : false;
    }

    constructor(element, dialogController, dialogService, userService, fhirService: FhirService, carePlanService: CarePlanService, i18n: I18N) {
        this.element = element;
        this.controller = dialogController;
        this.dialogService = dialogService;
        this.userService = userService;
        this.fhirService = fhirService;
        this.carePlanService = carePlanService
        this.i18n = i18n
        this.controller.settings.centerHorizontalOnly = true;
    }

    attached() {
        this.element.style.maxWidth = "860px";
    }

    async activate(data) {
        const formConfig = ConfigService.GetFormSettings(ConfigService.FormNames.Planning)

        this.isQualificationEnabled = formConfig && formConfig.settings && formConfig.settings.allowQualification

        if (data.procedure) {
            this.procedure = data.procedure;
            this.codeSystem = data.codeSystem;
        } else {
            this.request = data.request;
            this.procedureIndex = data.procedureIndex;
            this.procedure = data.request.procedures[data.procedureIndex].resource;
            this.codeSystem = this.request.codeSystem;
        }

        this.procedureRequest = data.procedureRequest;
        this.carePlan = data.carePlan || null;
        this.createMode = data.createMode || false;
        this.patient = data.patient;

        this.qualification = this.procedureRequest.resource.performerType && this.procedureRequest.resource.performerType.coding[0].system === 'http://nursiti.com/CodeSystem/qualification' ? this.i18n.tr(`qualification_${this.procedureRequest.resource.performerType.coding[0].code}`) : ''

        const prescriptionCategory = this.procedureRequest.resource.category && this.procedureRequest.resource.category.find((category) => category.coding[0].system === 'http://nursiti.com/CodeSystem/presc-needed')

        if (prescriptionCategory) {
            this.prescriptionPresent = prescriptionCategory.coding[0].code
        }

        this.isPrescriptionEnabled = formConfig && formConfig.settings && formConfig.settings.allowPrescription
        this.isPrescriptionStrict = formConfig && formConfig.settings && formConfig.settings.prescriptionNeededStrict
        this.isTaskPrescriptionNeeded = this.codeSystem.properties.prescNeeded

        const rootCode = this.codeSystem.code.split('.')[0];

        if (rootCode == 100) {
            this.isDiagnostic = true;

            if (this.procedure.performer && this.procedure.performer[0] && this.procedure.performer[0].actor && this.procedure.performer[0].actor.reference) {
                const practitionerId = this.procedure.performer[0].actor.reference.split('/')[1];

                this.fhirService.get(`${ResourceType.practitioner}/${practitionerId}`).then((practitioner: any) => {
                    let names = practitioner.name;
                    if (names && names.length > 0) {
                        let official = names.find(o => o.use === "official");
                        if (!official) official = names[0];

                        const lastName = official.family;
                        const firstName = official.given?.join(', ');
                        const fullName = [];

                        if (lastName) {
                            fullName.push(lastName);
                        }

                        if (firstName) {
                            fullName.push(firstName);
                        }

                        if (fullName.length > 0) {
                            this.diagnosticPerformer = fullName.join(', ');
                        }
                    }
                });
            }
        }

        const performedPeriodStart = moment(this.procedure.performedPeriod.start);
        const performedPeriodEnd = moment(this.procedure.performedPeriod.end);
        const duration = moment.duration(performedPeriodEnd.diff(performedPeriodStart));

        this.headerTitle = this.procedure.text;
        this.dateObject = performedPeriodStart.toDate();
        this.dateValue = performedPeriodStart.format(this.dpOptions.format);
        this.duration = duration.asMinutes();
        this.comment = this.procedure.note && this.procedure.note[0].text || '';
        this.shiftReport = this.procedure.hasOwnProperty('followUp');
        this.notDone = this.procedure.hasOwnProperty('notDone') ? this.procedure.notDone : false;
        // this.lastEdit = moment(this.procedure.meta.lastUpdated).format('DD.MM.YYYY HH:mm');

        if (this.procedure.status === fhirEnums.EventStatus.preparation) {
            this.currentStatus = ProcedureStatus.Done;
        } else if (this.procedure.status === fhirEnums.EventStatus.completed) {
            if (!this.notDone) {
                this.currentStatus = ProcedureStatus.Done;
                this.statusText = translations.translate('done');
            } else {
                this.currentStatus = ProcedureStatus.NotDone;
                this.statusText = translations.translate('not_done');
                this.setReason();
            }
        } else if (this.procedure.status === 'not-done') {
            this.currentStatus = ProcedureStatus.NotDone;
            this.statusText = translations.translate('not_done');
            this.setReason();
        } else if (this.procedure.status === fhirEnums.EventStatus.aborted || this.procedure.status === 'stopped') {
            this.currentStatus = ProcedureStatus.Canceled;
            this.statusText = translations.translate('canceled');
            this.setReason();
        } else if (this.procedure.status === fhirEnums.EventStatus.enteredInError) {
            this.currentStatus = ProcedureStatus.EnteredInError;
            this.statusText = translations.translate('entered-in-error');
        }

        this.loadDiagnoses()
    }

    async loadDiagnoses() {
        this.isLoading = true
        this.conditions = await this.loadConditions()
        this.mappings = await this.carePlanService.getMappings(this.patient);
        this.generateDiagnosisQuestionnaireItemsFromCurrentMapping()

        const procedureRequest = this.procedureRequest.resource;
        const code = procedureRequest.code.coding[0].code;

        this.activeMappings.forEach((activeMapping) => {
            activeMapping.items.forEach((item) => {
                if (item.taskIds && item.taskIds.indexOf(code) > -1) {
                    this.diagnoses.push({
                        category: activeMapping,
                        item
                    })
                }
            });
        });

        this.isLoading = false
    }

    async loadConditions() {
        const conditions = [];
        const bundleBuilder = this.fhirService.bundleBuilder(BundleType.transaction);

        if (this.carePlan?.addresses) {
            for (let i = 0; i < this.carePlan.addresses.length; i++) {
                const id = this.carePlan.addresses[i].reference.split('/')[1];

                bundleBuilder.add({
                    resourceType: ResourceType.condition,
                    id: id
                }, HTTPVerb.get, (condition) => {
                    conditions.push(condition);
                });
            }

            await bundleBuilder.exec();
        }

        return conditions;
    }

    generateDiagnosisQuestionnaireItemsFromCurrentMapping() {
        this.activeMappings = [];

        this.mappings.forEach((mapping) => {
            this.conditions.forEach((condition) => {
                const conditionCode = condition.code && condition.code.coding && condition.code.coding[0].code;

                if (!conditionCode) {
                    return;
                }

                if (mapping.diagnoseID === conditionCode) {
                    let curDisplay = this.activeMappings.find(o => o.id == mapping.groupId);

                    if (!curDisplay) {
                        const isFirstAdded = this.activeMappings.length === 0;

                        curDisplay = {
                            internalId: NitTools.UidName(),
                            id: mapping.groupId,
                            title: mapping.groupTitle,
                            items: [],
                            itemsShown: isFirstAdded,
                            active: false,
                            itemTasksActive: 0
                        };

                        this.activeMappings.push(curDisplay);
                    }

                    if (mapping.taskIds) {
                        curDisplay.items.push({
                            diagnoseID: mapping.diagnoseID,
                            diagnoseText: mapping.diagnoseText || mapping.diagnoseID,
                            taskIds: mapping.taskIds,
                            active: false,
                            tasksActive: 0
                        });
                    } else {
                        console.warn(`${mapping.diagnoseID} has no matching task IDs`)
                    }
                }
            })
        });
    }

    setReason() {
        if (FhirService.FhirVersion > 3) {
            if (this.procedure.statusReason) {
                this.otherReason = this.procedure.statusReason.text;

                if (this.procedure.statusReason.coding) {
                    const reason = this.procedure.statusReason.coding.find((c) => c.system === RuntimeInfo.SystemHeader + '/procedure-not-done-reason');

                    if (reason) {
                        this.reason = reason.code;
                    }
                }
            }
        } else {
            if (this.procedure.notDoneReason) {
                this.otherReason = this.procedure.notDoneReason.text;

                if (this.procedure.notDoneReason.coding) {
                    const reason = this.procedure.notDoneReason.coding.find((c) => c.system === RuntimeInfo.SystemHeader + '/procedure-not-done-reason');

                    if (reason) {
                        this.reason = reason.code;
                    }
                }
            }
        }
    }

    pickerChanged() {
    }

    deleteShiftReport(id) {
        return this.fhirService.delete({
            id,
            resourceType: fhirEnums.ResourceType.observation
        });
    }

    async submit() {
        let shiftReportId = null;

        if (this.isReadOnly || this.isLoading) {
            return;
        }

        switch (this.currentStatus) {
            case ProcedureStatus.Done: {
                this.procedure.status = fhirEnums.EventStatus.completed;
                this.procedure.notDone = false;
                this.procedure.note = [{text: this.comment}];
                this.procedure.performedPeriod.start = this.dateObject;
                this.procedure.performedPeriod.end = moment(this.dateObject).add(this.duration, 'minutes');

                delete this.procedure.notDoneReason;
                delete this.procedure.statusReason;

                break;
            }
            case ProcedureStatus.NotDone: {
                if (FhirService.FhirVersion > 3) {
                    this.procedure.status = 'not-done';
                    this.procedure.statusReason = {
                        coding: [{
                            system: RuntimeInfo.SystemHeader + '/procedure-not-done-reason',
                            code: this.reason
                        }],
                        text: this.reason == 'other' ? this.otherReason : null
                    };
                } else {
                    this.procedure.status = fhirEnums.EventStatus.completed;
                    this.procedure.notDone = true;
                    this.procedure.notDoneReason = {
                        coding: [{
                            system: RuntimeInfo.SystemHeader + '/procedure-not-done-reason',
                            code: this.reason
                        }],
                        text: this.reason == 'other' ? this.otherReason : null
                    };
                }
                break;
            }
            case ProcedureStatus.Canceled: {
                if (FhirService.FhirVersion > 3) {
                    this.procedure.status = 'stopped';
                    this.procedure.statusReason = {
                        coding: [{
                            system: RuntimeInfo.SystemHeader + '/procedure-not-done-reason',
                            code: this.reason
                        }],
                        text: this.reason == 'other' ? this.otherReason : null
                    };
                } else {
                    this.procedure.status = fhirEnums.EventStatus.aborted;
                    this.procedure.notDone = true;
                    this.procedure.notDoneReason = {
                        coding: [{
                            system: RuntimeInfo.SystemHeader + '/procedure-not-done-reason',
                            code: this.reason
                        }],
                        text: this.reason == 'other' ? this.otherReason : null
                    };
                }
                break;
            }
            case ProcedureStatus.EnteredInError: {
                this.procedure.status = fhirEnums.EventStatus.enteredInError;
                this.shiftReport = false;
            }
        }

        if (this.userService.practitioner) {
            this.procedure.performer = [{
                actor: {
                    reference: `Practitioner/${this.userService.practitioner.id}`,
                    display: this.userService.fullNameOrUsername
                }
            }];
        }

        if (this.isPrescriptionEnabled && (this.isTaskPrescriptionNeeded || this.prescriptionPresent !== 'not-needed')) {
            const procedureRequestPrescriptionCategoryIndex = this.procedureRequest.resource.category && this.procedureRequest.resource.category.findIndex((category) => category.coding[0].system === 'http://nursiti.com/CodeSystem/presc-needed')

            const category = {
                coding: [{
                    system: 'http://nursiti.com/CodeSystem/presc-needed',
                    code: this.prescriptionPresent,
                    display: this.prescriptionPresent
                }]
            }

            if (procedureRequestPrescriptionCategoryIndex > -1) {
                this.procedureRequest.resource.category[procedureRequestPrescriptionCategoryIndex] = category
            } else {
                this.procedureRequest.resource.category.push(category)
            }

            if (this.prescriptionPresent !== 'present') {
                delete this.procedureRequest.resource.supportingInfo
            }

            this.procedureRequest.isPrescription = true

            await this.fhirService.update(this.procedureRequest.resource)
        } else {
            const procedureRequestPrescriptionCategoryIndex = this.procedureRequest.resource.category && this.procedureRequest.resource.category.findIndex((category) => category.coding[0].system === 'http://nursiti.com/CodeSystem/presc-needed')
            if (procedureRequestPrescriptionCategoryIndex > -1) {
                this.procedureRequest.isPrescription = false
                this.procedureRequest.resource.category.splice(procedureRequestPrescriptionCategoryIndex, 1)
                await this.fhirService.update(this.procedureRequest.resource)
            }
        }

        if (this.shiftReport) {
            if (!this.procedure.hasOwnProperty('followUp')) {
                let comment;

                if (this.currentStatus === ProcedureStatus.NotDone || this.currentStatus === ProcedureStatus.Canceled) {
                    if (this.reason === 'other') {
                        comment = this.otherReason
                    } else {
                        comment = translations.translate('not_done_reason_' + this.reason)
                    }
                } else {
                    comment = this.comment;
                }

                const report: any = {
                    resourceType: fhirEnums.ResourceType.observation,
                    subject: {reference: `${fhirEnums.ResourceType.patient}/${this.patient.id}`},
                    [FhirService.FhirVersion > 3 ? 'encounter' : 'context']: {reference: `${fhirEnums.ResourceType.encounter}/${this.patient.encounterId}`},
                    status: 'registered',
                    code: {
                        coding: [{
                            system: LOINC.SYSTEM,
                            code: LOINC.CODES.REPORT.code
                        }]
                    },
                    effectiveDateTime: this.dateObject,
                    valueString: (this.procedureRequest.isControlled ? `[${translations.translate('control_measure')}] ` : '') + translations.translate(this.currentStatus == 'done' ? ProcedureStatus.Done : (this.currentStatus == ProcedureStatus.NotDone ? 'not_done' : this.currentStatus)) + ' um ' + moment(this.dateObject).format('DD.MM.YYYY HH:mm') + '. ' + this.codeSystem.display + '. ' + comment,
                    component: [{
                        code: {
                            text: 'shift'
                        },
                        valueString: 'day'
                    }, {
                        code: {
                            text: 'mark-supplement'
                        },
                        valueString: 'false'
                    }, {
                        code: {
                            text: 'nursing-transfer'
                        },
                        valueString: 'false'
                    },
                        {
                            code: {
                                text: 'additional-info'
                            },
                            valueString: 'false'
                        }]
                };

                if (this.userService.practitioner) {
                    report.performer = [{
                        reference: `Practitioner/${this.userService.practitioner.id}`,
                        display: this.userService.fullNameOrUsername
                    }];
                }

                this.procedure.followUp = {
                    text: 'shift_report'
                };

                const result = await this.fhirService.create(report);

                this.procedure.partOf = {reference: `${fhirEnums.ResourceType.observation}/${result.id}`};
            }
        } else {
            if (this.procedure.hasOwnProperty('followUp') && this.procedure.hasOwnProperty('partOf')) {
                shiftReportId = this.procedure.partOf[0].reference.split('/')[1];

                delete this.procedure.partOf;
                delete this.procedure.followUp;
            }
        }

        if (this.request) {
            this.request.procedures[this.procedureIndex].resource = await this.fhirService.update(this.procedure);
        }

        if (shiftReportId) {
            await this.deleteShiftReport(shiftReportId);
        }

        if (this.createMode) {
            this.controller.ok(await this.fhirService.create(this.procedure));
        } else {
            this.controller.ok();
        }
    }

    close() {
        this.controller.cancel();
    }

    async selectPrescription() {
        await this.dialogService.open({
            viewModel: ModalPlanningPrescription,
            model: {
                codeSystem: await this.carePlanService.getGMTCodeSystem(this.patient),
                patient: this.patient,
                procedureRequest: this.procedureRequest.resource
            },
            lock: true
        }).whenClosed(async (dialogResult) => {
            if (dialogResult.wasCancelled) return;

            this.procedureRequest.resource.supportingInfo = [{
                reference: `Media/${dialogResult.output}`
            }]
        })
    }
}

enum ProcedureStatus {
    Planned = 'planned',
    Done = 'done',
    NotDone = 'notDone',
    Canceled = 'canceled',
    EnteredInError = 'entered-in-error'
}
